// Styles
import styled, { keyframes } from 'styled-components';
import { fadeInUp } from 'react-animations';

const fadeInUpAnimation = keyframes`${fadeInUp}`;

const ContactContainer = styled.div`
  max-width: 32rem;
  margin: 0 auto;
  padding: 1rem 35px;
  background-color: white;
  margin-top: 6rem;
  margin-bottom: -12rem;
  box-shadow: 0px 0px 6px 2px grey;
  animation: 1s ${fadeInUpAnimation};
`;

const HeaderTitle = styled.h1`
  text-align: center;
  margin: 8px;
  font-size: 30px;
`;

const HeaderSubTitle = styled.p`
  margin: 6px;
  text-align: center;
  font-size: 19px;
`;

const Button = styled.button`
  padding: 15px 25px;
  border-radius: 5px;
  background-color: #1f6fff;
  font-size: 12px;
  font-weight: 600;  
  text-transform: uppercase;
  color: white;
  border: none;
  cursor: pointer;
`;

const FieldsContainer = styled.div`
 
`;

const FieldTitle = styled.p`
 
`;

const FieldInput = styled.input`
  width: 100%;
  background: #F2F3F7 !important;
  border: none;
  font-size: 15px;
  height: 40px;
  border-radius: 4px;
  transition: 0.3s;

  &:focus-visible{
    outline: none;
    box-shadow: 2px 2px 4px 1px #808080ad;
  }

  &:hover{
    background-color: #dddfe7 !important;
  }
`;

const FieldTextArea = styled.textarea`
  width: 100%;
  max-width: 100%;
  background: #F2F3F7 !important;
  border: none;
  font-size: 15px;
  border-radius: 4px;
  transition: 0.3s;

  &:focus-visible{
    outline: none;
    box-shadow: 2px 2px 4px 1px #808080ad;
  }

  &:hover{
    background-color: #dddfe7 !important;
  }
`;

const InfoSection = styled.div`
  padding-top: 12rem;
`;

const ContactFooter = styled.div`
  margin-top: 30px;  
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ErrorMessage = styled.span`
  color: red;
  font-weight: bold;
`;

const SuccessMessage = styled.span`
  color: green;
  font-weight: bold;
`;


export {
  ContactContainer,
  ContactFooter,
  HeaderTitle,
  HeaderSubTitle,
  Button,
  FieldsContainer,
  FieldTitle,
  FieldInput,
  FieldTextArea,
  InfoSection,
  ErrorMessage,
  SuccessMessage,
}