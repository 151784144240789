import React from 'react';

// Styles
import styled from 'styled-components';

const Container = styled.div`
  background-color: #CCCCFF;
  margin-top: auto;
  padding: 15px 25px 10px;
  background-color: #424549;
  color: #b3b9c1;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-around;
  font-weight: bold;
  font-size: 12px;
`;

const Footer = () => {
  return (
    <Container>
      <p>Copyright © Doublecheck. Todos os direitos reservados.</p>
      <div>
        <p>Av. Brigadeiro Faria Lima, 3477, 8º andar, Torre B, Itaim Bibi, São Paulo / SP - CEP: 04538-133</p>
        <p>contato@doublecheck.com.br - Telefone: 11 3010-0404</p>
      </div>
    </Container>
  );
}

export default Footer;
