import solutionBgImage from '../../assets/skylineBuilding.jpg';

// Styles
import styled, { keyframes } from 'styled-components';
import { fadeInUp } from 'react-animations';

const fadeInUpAnimation = keyframes`${fadeInUp}`;

const HeaderContent = styled.div`
  max-width: 38rem;
  margin: 0 auto;
  text-align: center;
  padding: 3rem 0px;    
`;

const HeaderTitle = styled.h1`
  color: white;
  font-size: 32px;
  animation: 1s ${fadeInUpAnimation};
`;

const HeaderSubTitle = styled.p`
  color: white;
  font-size: 21px;
  animation: 1.5s ${fadeInUpAnimation};
`;

const IframeSection = styled.div`
margin-top: 3rem;
// position: relative;
// overflow: hidden;
// width: 720px;
height: 405px;
// //   overflow: hidden;
// //   padding-top: 56.25%;

// iframe {
//   // position: absolute;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   right: 0;
//   width: 100%;
//   height: 100%;
//   border: none;
// }



iframe {
  width: ${props => props.isMobile ? '100%' : '720px'};
  height: ${props => props.isMobile ? '100%' : '405px'} ;
  margin: 0 auto;
  background-color: #777;
  display: block;
    border-style:none;
}
`

const Button = styled.button`
  margin-top: 30px;  
  padding: 15px 25px;
  border-radius: 5px;
  background-color: #1f6fff;
  font-size: 14px;
  font-weight: 600;  
  text-transform: uppercase;
  color: white;
  border: none;
  cursor: pointer;
  animation: 1.7s ${fadeInUpAnimation};
`;

const AboutSection = styled.section`
  margin: 0 auto;
  padding: 3rem 1rem 1rem;
`;

const AboutItem = styled.div`
  padding: 10px;
`;

const AboutItemHeader = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 1130px) {
      justify-content: center;
  }   
`;

const AboutItemTitle = styled.span`
  font-size: 20px;
  margin: 5px 20px;
  font-weight: bold;
  text-align: center;
`;

const AboutItemIcon = styled.img`
  width: 35px;
  text-align: center;
`;

const AboutItemDescriptionContainer = styled.p`
  font-size: 16px;
  margin-top: 22px;
`;

const SolutionSection = styled.section`
  padding: 35px 7px;
  background-image: linear-gradient(to bottom, rgb(255 255 255),rgb(255 255 255 / 15%)), url(${solutionBgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 85%;    
`;

const SolutionContent = styled.div`
  max-width: 47rem;
  margin: 0 auto;
  text-align: center;
  padding: 1rem 0px 8rem;
`;

const SolutionSectionTitle = styled.h1`
  font-size: 3rem;
`;

const SolutionSectionDescription = styled.p`
  font-size: 21px;
`;


export {
  HeaderContent,
  HeaderTitle,
  HeaderSubTitle,
  Button,
  AboutSection,
  AboutItem,
  AboutItemHeader,
  AboutItemTitle,
  AboutItemIcon,
  AboutItemDescriptionContainer,
  SolutionSection,
  SolutionContent,
  SolutionSectionTitle,
  SolutionSectionDescription,
  IframeSection
}