import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import Footer from './components/Footer';

import Home from './pages/Home/Home';
import Contact from './pages/Contact/Contact';
import Factor from './pages/MainApplications/Factor/Factor';
import Onboarding from './pages/MainApplications/Onboarding/Onboarding';
import Manager from './pages/MainApplications/Manager/Manager';

function App() {
  return (
    <Router>
      {/* <Route render={() => <Redirect to={{pathname: "/"}} />} /> */}
      <div className="App">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/contato">
            <Contact />
          </Route>
          <Route path="/solucoes/factor">
            <Factor />
          </Route>
          <Route path="/solucoes/onboarding">
            <Onboarding />
          </Route>
          <Route path="/solucoes/gestor">
            <Manager />
          </Route>
          <Route path="*">
            <Redirect to={{ pathname: "/" }} />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
