import React from 'react';

import { useHistory } from "react-router-dom";

// Components
import { Col, Row } from 'react-grid-system';

import Navbar from '../../../components/Navbar';

import { Header } from '../../../global/Styles';
import {
  HeaderContent, HeaderTitle, HeaderSubTitle, AboutTitle, AboutSection, AboutSubTitle, AboutItemTitle,
  AboutItemIcon, AboutItemDescriptionContainer, AboutItemHeader, OddRow, EvenRow, DescWrapper, Button,
  Card, CardDescription, CardIcon, CardsWrapper, AboutWrapper, CardSizeImg
} from '../MainApplicationsStyles';

const Factor = () => {
  const history = useHistory();

  const isMobile = window.innerWidth < 560;
  

  return (
    <div>
      <Header>
        <Navbar />
        <HeaderContent>
          <HeaderTitle>
            FACTOR Phasellus sed neque sit amet mi cursus porta at ac augue.
          </HeaderTitle>
          <HeaderSubTitle>
            Duis enim mauris, ullamcorper vel risus iaculis, ullamcorper lobortis leo. Pellentesque lobortis tellus dui, vitae consequat nisl dictum quis. Sed dapibus pulvinar lobortis. Aliquam nec vulputate nunc.
          </HeaderSubTitle>
        </HeaderContent>
      </Header>
      {/* About */}
      <AboutSection>
        <CardsWrapper>
          <Row>
            <Col lg={12} xl={4} style={{padding: isMobile ? 0 : '0px 15px'}}>
              <Card>
                <AboutItemHeader>
                  <AboutItemTitle>Lorem ipsum dolor sit amet</AboutItemTitle>
                  <CardIcon alt="" class="feature-info-icon" src='https://uploads-ssl.webflow.com/6169e28ac4fe0ec7626c6e6f/6169e28bc4fe0e8fd46c710c_Icon-megaphone-blue.png' />
                  <CardDescription>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</CardDescription>
                </AboutItemHeader>
              </Card>
            </Col>
            <Col lg={12} xl={4} style={{padding: isMobile ? 0 : '0px 15px'}}>
              <Card>
                <AboutItemHeader>
                  <AboutItemTitle>Lorem ipsum dolor sit amet</AboutItemTitle>
                  <CardIcon alt="" class="feature-info-icon" src='https://uploads-ssl.webflow.com/6169e28ac4fe0ec7626c6e6f/6169e28bc4fe0e91f16c7120_Icon-pencil-blue.png' />
                  <CardDescription>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</CardDescription>
                </AboutItemHeader>
              </Card>
            </Col>
            <Col lg={12} xl={4} style={{padding: isMobile ? 0 : '0px 15px'}}>
              <Card>
                <AboutItemHeader>
                  <AboutItemTitle>Lorem ipsum dolor sit amet</AboutItemTitle>
                  <CardIcon alt="" class="feature-info-icon" src='https://uploads-ssl.webflow.com/6169e28ac4fe0ec7626c6e6f/6169e28bc4fe0e8fd46c710c_Icon-megaphone-blue.png' />
                  <CardDescription>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</CardDescription>
                </AboutItemHeader>
              </Card>
            </Col>
            <Col lg={12} xl={4} style={{padding: isMobile ? 0 : '0px 15px'}}>
              <Card>
                <AboutItemHeader>
                  <AboutItemTitle>Lorem ipsum dolor sit amet</AboutItemTitle>
                  <CardIcon alt="" class="feature-info-icon" src='https://uploads-ssl.webflow.com/6169e28ac4fe0ec7626c6e6f/6169e28bc4fe0e05d26c7174_Icon-camera-blue.png' />
                  <CardDescription>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</CardDescription>
                </AboutItemHeader>
              </Card>
            </Col>
            <Col lg={12} xl={4} style={{padding: isMobile ? 0 : '0px 15px'}}>
              <Card>
                <AboutItemHeader>
                  <AboutItemTitle>Lorem ipsum dolor sit amet</AboutItemTitle>
                  <CardIcon alt="" class="feature-info-icon" src='https://uploads-ssl.webflow.com/6169e28ac4fe0ec7626c6e6f/6169e28bc4fe0e57ff6c7110_Icon-thumb-blue.png' />
                  <CardDescription>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</CardDescription>
                </AboutItemHeader>
              </Card>
            </Col>
            <Col lg={12} xl={4} style={{padding: isMobile ? 0 : '0px 15px'}}>
              <Card>
                <AboutItemHeader>
                  <AboutItemTitle>Lorem ipsum dolor sit amet</AboutItemTitle>
                  <CardIcon alt="" class="feature-info-icon" src='https://uploads-ssl.webflow.com/6169e28ac4fe0ec7626c6e6f/6169e28bc4fe0e5a526c71a9_Icon-plane-blue.png' />
                  <CardDescription>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</CardDescription>
                </AboutItemHeader>
              </Card>
            </Col>
          </Row>
        </CardsWrapper>
        <OddRow>
          <Col lg={12} xl={6}>
            <img width={isMobile ? 300 : 560} src='https://uploads-ssl.webflow.com/6169e28ac4fe0ec7626c6e6f/6169e28bc4fe0e09166c71cd_Browser-2.png' />
          </Col>
          <Col lg={12} xl={6}>
            <AboutWrapper>
              <AboutTitle>Maecenas vitae metus a ligula finibus.</AboutTitle>
              <AboutSubTitle>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.</AboutSubTitle>
            </AboutWrapper>
            <Button onClick={() => history.push('/contato')}>Saiba mais</Button>
            <Button color='#5e5e5e' onClick={() => history.push('/contato')}>Cadastre-se</Button>
          </Col>
        </OddRow>
        <EvenRow>
          <Col lg={12} xl={6}>
            <AboutWrapper>
              <AboutTitle>Maecenas vitae metus a ligula finibus.</AboutTitle>
              <AboutSubTitle>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.</AboutSubTitle>
            </AboutWrapper>
            <Button onClick={() => history.push('/contato')}>Saiba mais</Button>
          </Col>
          <Col lg={12} xl={6}>
            <img width={isMobile ? 300 : 560} src='https://uploads-ssl.webflow.com/6169e28ac4fe0ec7626c6e6f/6169e28bc4fe0e5f8d6c71da_Browser-3.png' />
          </Col>
        </EvenRow>
        <OddRow>
          <Col lg={12} xl={6}>
            <CardSizeImg width={isMobile ? 300 : 560} src='https://uploads-ssl.webflow.com/6169e28ac4fe0ec7626c6e6f/6169e28bc4fe0e85316c71d6_Browser-1.png' />
          </Col>
          <Col lg={12} xl={6}>
            <AboutWrapper>
              <AboutTitle>Maecenas vitae metus a ligula finibus.</AboutTitle>
              <AboutSubTitle>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero.</AboutSubTitle>
              <AboutItemHeader>
                <AboutItemIcon alt="" class="feature-info-icon" src='https://uploads-ssl.webflow.com/6169e28ac4fe0ec7626c6e6f/6169e28bc4fe0e05d26c7174_Icon-camera-blue.png' />
                <DescWrapper>
                  <AboutItemTitle>Lorem ipsum dolor sit amet</AboutItemTitle>
                  <AboutItemDescriptionContainer>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</AboutItemDescriptionContainer>
                </DescWrapper>
              </AboutItemHeader>
              <AboutItemHeader>
                <AboutItemIcon alt="" class="feature-info-icon" src='https://uploads-ssl.webflow.com/6169e28ac4fe0ec7626c6e6f/6169e28bc4fe0e57ff6c7110_Icon-thumb-blue.png' />
                <DescWrapper>
                  <AboutItemTitle>Lorem ipsum dolor sit amet</AboutItemTitle>
                  <AboutItemDescriptionContainer>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</AboutItemDescriptionContainer>
                </DescWrapper>
              </AboutItemHeader>
              <AboutItemHeader>
                <AboutItemIcon alt="" class="feature-info-icon" src='https://uploads-ssl.webflow.com/6169e28ac4fe0ec7626c6e6f/6169e28bc4fe0e8fd46c710c_Icon-megaphone-blue.png' />
                <DescWrapper>
                  <AboutItemTitle>Lorem ipsum dolor sit amet</AboutItemTitle>
                  <AboutItemDescriptionContainer>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</AboutItemDescriptionContainer>
                </DescWrapper>
              </AboutItemHeader>
            </AboutWrapper>
          </Col>
        </OddRow>
      </AboutSection>
    </div>
  );
}

export default Factor;
