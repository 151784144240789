// Styles
import styled from 'styled-components';

import headerImg from '../assets/malzoni3.jpg';

const Header = styled.div`
  padding: 20px 7px;
  background-image: linear-gradient(to bottom, rgb(14 40 88 / 90%), rgb(0 0 0 / 90%)), url(${props => props?.src ?? headerImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 85%;    
`;

export { Header };