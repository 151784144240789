// eslint-disable-next-line no-undef
const MAIN_URL_API = process.env.REACT_APP_MAIN_URL;

const API_PATHS = {
  SEND_EMAIL: '/email/contact'
};

export {
  API_PATHS,
  MAIN_URL_API,
};