import React, { useState } from 'react';

import Navbar from '../../components/Navbar';

import headerImg from '../../assets/skylineBuilding.jpg';
import { Header } from '../../global/Styles';
import {
  ContactContainer, HeaderTitle, HeaderSubTitle, Button, FieldsContainer, FieldTitle, FieldInput, FieldTextArea,
  InfoSection, ContactFooter, ErrorMessage, SuccessMessage
} from './ContactStyles';

import { notExists, isEmptyString, isValidEmail, exists } from '../../utils/utils';
import { sendEmailContact } from '../../service/contact';

import { LoadingOutlined } from '@ant-design/icons';

const Contact = () => {
  const defaultFields = { name: '', email: '', message: '' }
  const [fields, setFields] = useState(defaultFields);
  const [hasSend, sethasSend] = useState(false);
  const [loadingSengEmail, setLoadingSengEmail] = useState(false);
  const [sendEmailResponse, setSendEmailResponse] = useState(null);

  const notHavename = notExists(fields?.name) || isEmptyString(fields?.name);
  const invalidEmail = notExists(fields?.email) || isEmptyString(fields?.email) || !isValidEmail(fields?.email);
  const notHaveMessage = notExists(fields?.message) || isEmptyString(fields?.message);
  const hasInvalidFields = notHavename || invalidEmail || notHaveMessage;

  const sendEmailReponseMessage = sendEmailResponse
    ? <SuccessMessage>Email enviado com sucesso!</SuccessMessage>
    : <ErrorMessage>Ocorreu um erro ao enviar o email</ErrorMessage>;

  function handleInputs(event) {
    const { target } = event;
    const { value, name } = target;
    setFields({ ...fields, [name]: value });
    sethasSend(false);
    setSendEmailResponse(null);
  }

  async function sendEmail() {
    sethasSend(true);
    setSendEmailResponse(null);

    if (hasInvalidFields || loadingSengEmail) return;

    setLoadingSengEmail(true);
    const payload = { ...fields };
    const response = await sendEmailContact(payload);
    setSendEmailResponse(response);
    setLoadingSengEmail(false);
  }

  const renderErrorMessage = () => {
    if (notHavename || notHaveMessage) return "Preencha todos os campos";
    if (invalidEmail) return "Informe um email válido";
  }

  return (
    <div>
      <Header src={headerImg}>
        <Navbar />
        <ContactContainer>
          <HeaderTitle>
            Entre em contato
          </HeaderTitle>
          <HeaderSubTitle>
            Tem alguma pergunta? Entre em contato!
          </HeaderSubTitle>
          <form>
            <FieldsContainer>
              <FieldTitle>Nome</FieldTitle>
              <FieldInput value={fields.name} name='name' onChange={handleInputs} type="text" />
              <FieldTitle>Email</FieldTitle>
              <FieldInput value={fields.email} name='email' onChange={handleInputs} type="text" />
              <FieldTitle>Mensagem</FieldTitle>
              <FieldTextArea value={fields.message} id="message" name="message" onChange={handleInputs} rows="4" cols="50">
                At w3schools.com you will learn how to make a website. They offer free tutorials in all web development technologies.
              </FieldTextArea>
            </FieldsContainer>
          </form>
          <ContactFooter>
            <Button onClick={sendEmail}>
              {loadingSengEmail && <LoadingOutlined />}
              <span style={{ marginLeft: loadingSengEmail ? 10 : 0 }}>{loadingSengEmail ? 'Enviando' : 'Enviar Email'}</span>
            </Button>
            {hasSend && hasInvalidFields && <ErrorMessage>{renderErrorMessage()}</ErrorMessage>}
            {exists(sendEmailResponse) && sendEmailReponseMessage}
          </ContactFooter>
        </ContactContainer>
      </Header>
      <InfoSection>
      </InfoSection>
    </div>
  );
}

export default Contact;
