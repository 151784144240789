import { Row } from 'react-grid-system';
// Styles
import styled, { keyframes } from 'styled-components';
import { fadeInUp } from 'react-animations';

const fadeInUpAnimation = keyframes`${fadeInUp}`;

const HeaderContent = styled.div`
  max-width: 38rem;
  margin: 0 auto;
  text-align: center;
  padding: 3rem 0px;    
`;

const CardsWrapper = styled.div`
  // text-align: 'center';
  width: 60%;
  margin: 0 auto;
  @media (max-width: 1130px) {
    width: 90%;
  }
  @media (max-width: 1600px) {
    width: 70%;
  }
`;

const Card = styled.div`
  float: left;
  // width: 250px;
  margin: 10px;
  padding: 12px 32px 32px 32px;  
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;


const CardSizeImg = styled.img`
width: 100% !important
@media (min-width: 1441px) {
  padding: 55px
}
  
`;

const CardIcon = styled.img`
  width: 30px;
  position: absolute;
  left: 80%;
  top: 15%;

  @media (max-width: 375px) {
    left: 74%
  }
  
`;


const CardDescription = styled.h3`
  font-size: 13px;
  color: #838383;
  margin-top: 22px !important;
  text-align: left;
  word-break: break-all;
`;


const HeaderTitle = styled.h1`
  color: white;
  font-size: 32px;
  animation: 1s ${fadeInUpAnimation};
`;

const HeaderSubTitle = styled.p`
  color: white;
  font-size: 21px;
  animation: 1.5s ${fadeInUpAnimation};
`;

const AboutTitle = styled.p`
  color: #333;
  font-size: 35px;
  animation: 1.5s ${fadeInUpAnimation};
`;

const AboutSubTitle = styled.p`
  color: #333;
  font-size: 15px;
  animation: 1.5s ${fadeInUpAnimation};
`;

const DescWrapper = styled.div`
`

const Button = styled.button`
  margin: 30px 20px 0px 0px;  
  padding: 15px 25px;
  border-radius: 5px;
  background-color: ${props => props.color ? props.color : '#1f6fff'} ;
  font-size: 12px;
  font-weight: 600;  
  text-transform: uppercase;
  color: white;
  border: none;
  cursor: pointer;
  animation: 1.7s ${fadeInUpAnimation};
`;

const AboutSection = styled.section`
  margin: 0 auto;
  padding: 3rem 1rem 1rem;
  animation: 1.7s ${fadeInUpAnimation};
`;

const OddRow = styled(Row)`
  padding-top: 100px;
  background: #fafafa;
  padding: 1rem 2rem 1rem 15rem;
  @media (max-width: 1130px) {
    text-align:center;
    padding: 0rem;
    margin: 0 auto
  }
`

const EvenRow = styled(Row)`
  padding: 1rem 15rem 1rem 35rem;
  @media (max-width: 1130px) {
    text-align:center;
    padding: 0rem;
    margin: 0 auto
  }

  @media (max-width: 1440px) and (min-width: 1131px) {
    padding: 1rem 15rem 1rem 10rem;
  }

`

const AboutItem = styled.div`
  padding: 10px;
`;

const AboutItemHeader = styled.div`
  padding-top: 20px;
  display: flex;
  align-items:${props => props.align ? props.align : 'center'} !important;
  flex-wrap: wrap;
  h2, h3{
    margin: 0
  }

  @media (max-width: 1130px) {
      justify-content: center;
  }   
`;

const AboutWrapper = styled.div`
  max-width: 500px;
  @media (max-width: 1130px) {
    margin: 0 auto;
  }
`;



const AboutItemIcon = styled.img`
  width: 25px;
  text-align: center;
  margin-right: 20px;
  padding: 10px;
  border: 1px #c9c9c9 solid;
  border-radius: 25%
`;

const AboutItemTitle = styled.h2`
  font-size: 17px;
  color: #333;
  font-weight: bold;
`;

const AboutItemDescriptionContainer = styled.h3`
  font-size: 14px;
  color: #838383;
  margin-top: 22px;
`;



export {
  HeaderContent,
  HeaderTitle,
  CardsWrapper,
  HeaderSubTitle,
  AboutSubTitle,
  AboutTitle,
  Card,
  CardDescription,
  Button,
  AboutSection,
  OddRow,
  EvenRow,
  AboutItem,
  AboutItemHeader,
  AboutItemTitle,
  AboutItemIcon,
  AboutWrapper,
  CardIcon,
  CardSizeImg,
  DescWrapper,
  AboutItemDescriptionContainer,
}