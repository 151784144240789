import React from 'react';

import { BrowserRouter as Router, useHistory } from "react-router-dom";

import logo from "../assets/doublecheckLowRes.png";

// Styles
import styled from 'styled-components';

const NavbarContainer = styled.div`
  padding: 40px 20px;
  border-bottom: 1px solid #a9a9a9;
  background-color: #ffffff00;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 425px) {
    justify-content: unset;
    display: inline-grid !important;
  }

`;

const Button = styled.button`
  background-color: #80808000;
  color: white;
  border: ${props => props?.useBorder === false ? 'none' : '1px solid'};
  border-radius: 5px;
  min-height: 28px;
  margin: 7px;
  cursor: pointer;
  font-size: 16px;
  padding: 6px;
`;

const DivAlign = styled.div`
  @media (max-width: 425px) {
    text-align:center !important
  }
`;

const NavBar = () => {
  const history = useHistory();

  function redirectPage(pageRoute) {
    if (pageRoute === null || pageRoute === undefined) return;
    history.push(`${pageRoute}`);
  }

  return (
    <Router>
      <NavbarContainer>
        <DivAlign>
          <img src={logo} style={{ width: '13rem' }} onClick={() => redirectPage('/')} style={{ cursor: 'pointer' }} />
        </DivAlign>
        <DivAlign style={{ textAlign: 'right' }}>
          <Button onClick={() => redirectPage('/')} useBorder={false} >A DOUBLECHECK</Button>
          <Button onClick={() => redirectPage('/contato')} useBorder={false}>CONTATO</Button>
          <Button onClick={() => window.location.href = "https://app.doublecheck.com.br"}>Acessar o sistema</Button>
        </DivAlign>
      </NavbarContainer>
    </Router>
  );
}

export default NavBar;
