export function exists(value) {
  if (value === undefined || value === null) return false;
  return true;
}

export function notExists(value) {
  if (value === undefined || value === null) return true;
  return false;
}

export function arrayNotEmpty(value) {
  if (notExists(value) || (exists(value) && Array.isArray(value) && value.length === 0)) return false;
  return true;
}

export const isEmptyString = (value) => {
  if (typeof value !== "string") return false;
  if (value.trim() === "") return true;
  return false;
}

export function isValidEmail(value) {
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(value);
}