import React from 'react';

import { useHistory } from "react-router-dom";

// Components
import { Row, Col } from 'react-grid-system';

import Navbar from '../../components/Navbar';

import { Header } from '../../global/Styles';
import {
  HeaderContent, HeaderTitle, HeaderSubTitle, Button, AboutSection, AboutItem, AboutItemTitle,
  AboutItemIcon, AboutItemDescriptionContainer, AboutItemHeader, SolutionSection, SolutionSectionTitle,
  SolutionSectionDescription, SolutionContent, IframeSection
} from './HomeStyles';

const Home = () => {
  const history = useHistory();

  return (
    <div>
      <Header>
        <Navbar />
        <HeaderContent>
          <HeaderTitle>
            Concentre informações e conhecimentos técnicos de forma totalmente digital.
          </HeaderTitle>
          <HeaderSubTitle>
            Criamos processos e pesquisas de forma online e real time e ensinamos tudo isso para o Software!
          </HeaderSubTitle>
          <Button onClick={() => history.push('/contato')}>Saiba mais</Button>
        </HeaderContent>
      </Header>
      <IframeSection isMobile={window.innerWidth <= 720}>
        <iframe
          style={{ margin: '0 auto' }}
          width="1280" height="720"
          src="https://www.youtube.com/embed/xS2MB0mGiGw"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen={true}
        />
      </IframeSection>
      {/* About */}
      <AboutSection>
        <Row style={{ margin: 0 }}>
          <Col xs={12} md={6} lg={12} style={{ padding: '30px 35px', textAlign: 'center' }}>
            <AboutItem style={{ maxWidth: '68rem', margin: '0 auto' }}>
              <AboutItemHeader style={{ justifyContent: 'center' }}>
                <AboutItemIcon alt="" class="feature-info-icon" src='https://uploads-ssl.webflow.com/6169e28ac4fe0ec7626c6e6f/6169e28bc4fe0e8fd46c710c_Icon-megaphone-blue.png' />
                <AboutItemTitle>Sistema inteligente</AboutItemTitle>
              </AboutItemHeader>
              <AboutItemDescriptionContainer>
                Utilizamos o princípio da assimetria de informações para municiar uma potente inteligência artificial capaz de checar
                inconsistências e pontos de atenção em milhares de títulos de crédito, gestão de carteiras
                de fundos de investimentos, KYC, entre outras funcionalidades, em uma fração de segundos.
              </AboutItemDescriptionContainer>
            </AboutItem>
          </Col>
          <Col xs={12} md={6} lg={4} style={{ padding: '30px 35px' }}>
            <AboutItem>
              <AboutItemHeader>
                <AboutItemIcon alt="" class="feature-info-icon" src='https://uploads-ssl.webflow.com/6169e28ac4fe0ec7626c6e6f/6169e28bc4fe0ea1866c7119_Icon-responsive.png' />
                <AboutItemTitle>Módulo Onboarding</AboutItemTitle>
              </AboutItemHeader>
              <AboutItemDescriptionContainer>
                <p>Módulo responsável pela pesquisa de novos prospects, pelo KYC.
                  Linkado com os mais diversos bureaus para uma análise completa de novos clientes, parceiros, fornecedores, entre outros.</p>

              </AboutItemDescriptionContainer>
            </AboutItem>
          </Col>
          <Col xs={12} md={6} lg={4} style={{ padding: '30px 35px' }}>
            <AboutItem>
              <AboutItemHeader>
                <AboutItemIcon alt="" class="feature-info-icon" src='https://uploads-ssl.webflow.com/6169e28ac4fe0ec7626c6e6f/6169e28bc4fe0edec96c714f_Icon-monitor-blue.png' />
                <AboutItemTitle>Módulo Gestor</AboutItemTitle>
              </AboutItemHeader>
              <AboutItemDescriptionContainer>
                <p>Criado para que o tomador de decisão tenha na sua frente, de forma online, todas as informações de seus ativos que possui sob gestão. Sem a necessidade de entrar em diversas plataformas de terceiros.</p>
              </AboutItemDescriptionContainer>
            </AboutItem>
          </Col>
          <Col xs={12} md={6} lg={4} style={{ padding: '30px 35px' }}>
            <AboutItem>
              <AboutItemHeader>
                <AboutItemIcon alt="" class="feature-info-icon" src='https://uploads-ssl.webflow.com/6169e28ac4fe0ec7626c6e6f/6169e28bc4fe0e05d26c7174_Icon-camera-blue.png' />
                <AboutItemTitle>Módulo Factor</AboutItemTitle>
              </AboutItemHeader>
              <AboutItemDescriptionContainer>
                <p>
                  Sistema de gerenciamento de recebíveis.
                  Com essa plataforma, o gestor poderá subir operações, acompanhar carteira, fazer boletagem, fazer simulação de riscos, gerar relatórios, etc...
                </p>

              </AboutItemDescriptionContainer>
            </AboutItem>
          </Col>
        </Row>
      </AboutSection>
      <SolutionSection>
        <SolutionContent>
          <SolutionSectionTitle>A MAIS COMPLETA FERRAMENTA DE GESTÃO</SolutionSectionTitle>
          <SolutionSectionDescription>
            Software SaaS criado a partir de necessidades verificadas no mercado financeiro em geral.
          </SolutionSectionDescription>
          <Button onClick={() => history.push('/contato')}>Solicitar Contato</Button>
        </SolutionContent>
      </SolutionSection>
    </div>
  );
}

export default Home;
